import { Entity, makeEntity } from '@/types/people'
import { makeSale, Sale } from '@/types/payments'
import { compareDays } from '@/utils/sorting'
import moment from 'moment'

export class Location {
  constructor(
    public id: number,
    public name: string
  ) {}
}

export class Classification {
  constructor(
    public id: number,
    public name: string
  ) {}
}

export class StoreArticleType {
  constructor(
    public id: number,
    public name: string,
    public price1: number,
    public price2: number,
    public price3: number
  ) {}
}

export class StoreArticleCategory {
  constructor(
    public id: number,
    public name: string
  ) {
  }
}

export class StoreEntityType {
  constructor(
    public id: number,
    public name: string,
    public priceIndex: number,
    public depositCheckAmount: number,
    public analyticAccount: number
  ) {
  }
}

export class Editor {
  constructor(
    public id: number,
    public name: string,
    public entity: number
  ) {}
}

export class Distributor {
  constructor(
    public id: number,
    public name: string,
    public entity: number
  ) {}
}

export class Provider {
  constructor(
    public id: number,
    public name: string,
    public entity: number
  ) {}
}

export class StoreItem {
  constructor(
    public id: number,
    public reference: string,
    public isAvailable: boolean,
    public description: string,
    public provider: Provider,
    public boughtOn: Date|null,
    public buyingPrice: number,
    public status: string,
    public location: Location
  ) {
  }
}

export class StoreArticleContent {
  constructor(
    public id: number,
    public name: string,
    public description: string
  ) {}
}

export class StoreArticle {
  constructor(
    public id: number,
    public name: string,
    public description: string,
    public ageFrom: number|null,
    public ageTo: number|null,
    public category: StoreArticleCategory,
    public type: StoreArticleType,
    public location: Location,
    public classification: Classification,
    public editor: Editor,
    public distributor: Distributor,
    public theme: string,
    public link: string,
    public usage1: string,
    public usage2: string,
    public usage3: string,
    public editionYear: number|null,
    public imageUrl: string,
    public items: StoreItem[],
    public contents: StoreArticleContent[]
  ) {
  }
}

export class StoreAdhesionType {
  constructor(
    public id: number,
    public name: string,
    public price: number,
    public maxBorrowingsNumber: number,
    public borrowingDuration: number,
    public borrowingPenalties: number,
    public penaltiesAmount: number,
    public totalBorrowingsNumber: number,
    public analyticAccount: number,
    public entityType: StoreEntityType
  ) {
  }
}

export class StoreAdhesion {
  constructor(
    public id: number,
    public entity: Entity,
    public adhesionType: StoreAdhesionType,
    public createdOn: Date,
    public createdBy: string,
    public cancelledOn: Date|null,
    public cancelledBy: string,
    public sale: Sale|null,
    public maxBorrowingsNumber: number,
    public totalBorrowingsNumber: number,
    public validFrom: Date,
    public validTo: Date|null,
    public currentBorrowingsCount: number,
    public totalBorrowingsCount: number
  ) {
  }

  public limit(): string {
    let limits: string[] = []
    if (this.cancelledOn) {
      return 'annulée'
    }
    if (this.totalBorrowingsNumber) {
      limits.push('' + this.totalBorrowingsCount + '/' + this.totalBorrowingsNumber)
    }
    if (this.maxBorrowingsNumber) {
      limits.push('' + this.currentBorrowingsCount + '/' + this.maxBorrowingsNumber)
    }
    return limits.join(' ')
  }

  public getLabel(): string {
    const limit = this.limit()
    let label = this.adhesionType.name
    if (limit) {
      label += ' ' + limit
    }
    return label
  }
}

export class Borrowing {
  constructor(
    public id: number,
    public item: StoreItem,
    public article: StoreArticle,
    public entity: Entity,
    public createdOn: Date,
    public createdBy: number,
    public borrowedOn: Date,
    public returnExpectedOn: Date,
    public penaltiesOn: Date|null,
    public returnedOn: Date|null,
    public returnedBy: string,
    public penaltiesAmount: number,
    public sale: Sale,
    public extraSales: Sale[],
    public cancelled: boolean
  ) {
  }

  public isReturned(): boolean {
    return this.returnedOn !== null
  }

  public isLate(): boolean {
    if (this.returnExpectedOn) {
      if (!this.returnedOn) {
        return compareDays(this.returnExpectedOn, moment().toDate()) < 0
      } else {
        return compareDays(this.returnExpectedOn, this.returnedOn) < 0
      }
    }
    return false
  }

  public isExpectedToday(): boolean {
    if (this.returnExpectedOn) {
      return compareDays(this.returnExpectedOn, moment().toDate()) === 0
    }
    return false
  }

  public hasPenalties(): boolean {
    if (this.penaltiesOn && this.isLate()) {
      if (!this.returnedOn) {
        return compareDays(this.penaltiesOn, moment().toDate()) <= 0
      } else {
        return compareDays(this.penaltiesOn, this.returnedOn) <= 0
      }
    }
    return false
  }

  public getStatus(): string {
    let words: string[] = []
    if (this.cancelled) {
      return 'annulé'
    }
    if (!this.isReturned()) {
      if (this.isExpectedToday()) {
        words.push('à rendre')
      } else {
        words.push('prêté')
      }
    }
    if (this.isLate()) {
      if (this.hasPenalties()) {
        words.push('pénalités')
      } else {
        words.push('retard')
      }
    }
    return words.join(' ')
  }
}

export class StoreDepositCheck {
  constructor(
    public id: number,
    public bank: string,
    public checkNumber: string,
    public checkAmount: number,
    public date: Date|null,
    public createdOn: Date
  ) {
  }
}

export function makeStoreArticleCategory(jsonData: any = null): StoreArticleCategory {
  if (!jsonData) {
    jsonData = {}
  }
  return new StoreArticleCategory(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export function makeStoreArticleType(jsonData: any = null): StoreArticleType {
  if (!jsonData) {
    jsonData = {}
  }
  return new StoreArticleType(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.price1 || 0,
    jsonData.price2 || 0,
    jsonData.price3 || 0
  )
}

export function makeClassification(jsonData: any = null): Classification {
  if (!jsonData) {
    jsonData = {}
  }
  return new Classification(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export function makeLocation(jsonData: any = null): Location {
  if (!jsonData) {
    jsonData = {}
  }
  return new Location(
    jsonData.id || 0,
    jsonData.name || ''
  )
}

export function makeEditor(jsonData: any = null): Editor {
  if (!jsonData) {
    jsonData = {}
  }
  return new Editor(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.entity || 0
  )
}

export function makeDistributor(jsonData: any = null): Distributor {
  if (!jsonData) {
    jsonData = {}
  }
  return new Distributor(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.entity || 0
  )
}

export function makeProvider(jsonData: any = null): Provider {
  if (!jsonData) {
    jsonData = {}
  }
  return new Provider(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.entity || 0
  )
}

export function makeStoreItem(jsonData: any = null): StoreItem {
  if (!jsonData) {
    jsonData = {}
  }
  return new StoreItem(
    jsonData.id || 0,
    jsonData.reference || '',
    !!jsonData.is_available,
    jsonData.description || '',
    makeProvider(jsonData.provider),
    jsonData.bought_on || null,
    (+jsonData.buying_price) || 0,
    jsonData.status || '',
    makeLocation(jsonData.location)
  )
}

export function makeStoreArticleContent(jsonData: any = null): StoreArticleContent {
  if (!jsonData) {
    jsonData = {}
  }
  return new StoreArticleContent(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.description || ''
  )
}

export function makeStoreArticle(jsonData: any = null): StoreArticle {
  if (!jsonData) {
    jsonData = {}
  }
  const items = jsonData.items || []
  const contents = jsonData.contents || []
  return new StoreArticle(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.description || '',
    jsonData.age_from || null,
    jsonData.age_to || null,
    makeStoreArticleCategory(jsonData.category),
    makeStoreArticleType(jsonData.type),
    makeLocation(jsonData.location),
    makeClassification(jsonData.classification),
    makeEditor(jsonData.editor),
    makeDistributor(jsonData.distributor),
    jsonData.theme || '',
    jsonData.link || '',
    jsonData.usage1 || '',
    jsonData.usage2 || '',
    jsonData.usage3 || '',
    jsonData.edition_year || null,
    jsonData.image_url || '',
    items.map((elt: any) => makeStoreItem(elt)),
    contents.map((elt: any) => makeStoreArticleContent(elt))
  )
}

export function makeBorrowing(jsonData: any = null): Borrowing {
  if (!jsonData) {
    jsonData = {}
  }
  return new Borrowing(
    jsonData.id || 0,
    makeStoreItem(jsonData.item),
    makeStoreArticle(jsonData.item ? jsonData.item.article : null),
    makeEntity(jsonData.entity),
    jsonData.created_on || null,
    jsonData.created_by || '',
    jsonData.borrowed_on || null,
    jsonData.return_expected_on || null,
    jsonData.penalties_on || null,
    jsonData.returned_on || null,
    jsonData.returned_by || '',
    jsonData.penalties_amount || 0,
    makeSale(jsonData.sale),
    jsonData.extra_sales ? jsonData.extra_sales.map((elt: any) => makeSale(elt)) : [],
    !!jsonData.cancelled
  )
}

export function makeStoreEntityType(jsonData: any = null): StoreEntityType {
  if (!jsonData) {
    jsonData = {}
  }
  return new StoreEntityType(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.price_index || 0,
    (+jsonData.deposit_check_amount) || 0,
    jsonData.analytic_account || 0
  )
}

export function makeStoreAdhesionType(jsonData: any = null): StoreAdhesionType {
  if (!jsonData) {
    jsonData = {}
  }
  return new StoreAdhesionType(
    jsonData.id || 0,
    jsonData.name || '',
    jsonData.price || 0,
    jsonData.max_borrowings_number || 0,
    jsonData.borrowing_duration || 0,
    jsonData.borrowing_penalties || 0,
    jsonData.penalties_amount || 0,
    jsonData.total_borrowings_number || 0,
    jsonData.analytic_account || 0,
    makeStoreEntityType(jsonData.entity_type)
  )
}

export function makeStoreAdhesion(jsonData: any = null): StoreAdhesion {
  if (!jsonData) {
    jsonData = {}
  }

  return new StoreAdhesion(
    jsonData.id || 0,
    makeEntity(jsonData.entity),
    makeStoreAdhesionType(jsonData.adhesion_type),
    jsonData.created_on,
    jsonData.created_by || '',
    jsonData.cancelled_on || null,
    jsonData.cancelled_by || '',
    jsonData.sale ? makeSale(jsonData.sale) : null,
    jsonData.max_borrowings_number || 0,
    jsonData.total_borrowings_number || 0,
    jsonData.valid_from,
    jsonData.valid_to || null,
    jsonData.current_borrowings_count || 0,
    jsonData.total_borrowings_count || 0
  )
}

export function makeStoreDepositCheck(jsonData: any = null): StoreDepositCheck {
  if (!jsonData) {
    jsonData = {}
  }
  return new StoreDepositCheck(
    jsonData.id || 0,
    jsonData.bank || '',
    jsonData.check_number || '',
    +(jsonData.check_amount) || 0,
    jsonData.date || null,
    jsonData.created_on || ''
  )
}
